import { mantleTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { getAddress } from 'viem'
import { SerializedFarmConfig } from '../src'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

// TODO: Add Mantle Chain
export const farmsV3 = defineFarmV3Configs([
  // {
  //   pid: 3,
  //   token0: mantleTokens.jtest,
  //   token1: mantleTokens.ctest,
  //   lpAddress: '0x76C11D43FA651dDc8e60084D8F34cBfaf0697144',
  //   feeAmount: FeeAmount.LOW,
  // },
  // {
  //   pid: 4,
  //   token0: mantleTokens.usdt,
  //   token1: mantleTokens.jtest,
  //   lpAddress: '0x575E6d22aaa6c6753eB8cd1D817CD2c577f5d17f',
  //   feeAmount: FeeAmount.LOWEST,
  // },
  // live farms[
  {
    pid: 6,
    token0: mantleTokens.usdt,
    token1: mantleTokens.wmnt,
    lpAddress: '0x61F96085eaaBFA3Af4a322CCa5d9ef77BE9973f8',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 7,
    token0: mantleTokens.wmnt,
    token1: mantleTokens.weth,
    lpAddress: '0xa84201c6112931a7d6221809a12f9BD633F71146',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 8,
    token0: mantleTokens.usdt,
    token1: mantleTokens.meth,
    lpAddress: '0xF659FeE5ABa8EF74FF28DCEf310122CCE33d06EE',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 9,
    token0: mantleTokens.usde,
    token1: mantleTokens.meth,
    lpAddress: '0x282782338523606aCCD15051B93C5439f64c757A',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 10,
    token0: mantleTokens.usdt,
    token1: mantleTokens.weth,
    lpAddress: '0xF2967106501D8C6EdB055d34B00ac845C941272B',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 11,
    token0: mantleTokens.lend,
    token1: mantleTokens.wmnt,
    lpAddress: '0xCdd52B2C2465868a0F5C5338B608fF6C784d0a50',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 12,
    token0: mantleTokens.idia,
    token1: mantleTokens.usdt,
    lpAddress: '0x9f12AB597fE22ba88e6B3dC3726E5B08393a9e58',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 13,
    token0: mantleTokens.cook,
    token1: mantleTokens.cmeth,
    lpAddress: '0x7b31D2039fB187068870e57Ae2439b38A96Af1bC',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 14,
    token0: mantleTokens.usdt,
    token1: mantleTokens.cmeth,
    lpAddress: '0x97d0447789E3520ad64bbae545d29cEcE5344EC7',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 15,
    token0: mantleTokens.usde,
    token1: mantleTokens.cmeth,
    lpAddress: '0x29ad7F10F79B9525C127e0bbce3461fa1cA7F21C',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 16,
    token0: mantleTokens.fbtc,
    token1: mantleTokens.cmeth,
    lpAddress: '0xE712d1C311D9F18a01b41201EF436D69f917a1DD',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 17,
    token0: mantleTokens.wmnt,
    token1: mantleTokens.cmeth,
    lpAddress: '0x22fDA4a251E81AB9C50b560A74769491Fc920BC6',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 20,
    token0: mantleTokens.fbtc,
    token1: mantleTokens.meth,
    lpAddress: '0xb71dD27a24F83Af1Ab5a1E1BdDa2F3197eBb30bE',
    feeAmount: FeeAmount.MEDIUM,
  },
  // {
  //   pid: 1,
  //   token0: mantleTokens.test1, // 0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE
  //   token1: mantleTokens.test2, // 0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8
  //   lpAddress: '0x16BCc2015c0214eD23f8949eAECF965CC91D07B8',
  //   feeAmount: FeeAmount.MEDIUM,
  // },
  // {
  //   pid: 1,
  //   token0: mantleTokens.jtest,
  //   token1: mantleTokens.ctest,
  //   lpAddress: '0x476dC8FCd73f5e2617231e3FB8b3513E4ffBf199',
  //   feeAmount: FeeAmount.LOWEST,
  // },
  // {
  //   pid: 2,
  //   token0: mantleTokens.jtest,
  //   token1: mantleTokens.ctest,
  //   lpAddress: '0x9DBdA0E1fb258AC90287DC3ddF91553D3F46bd3e',
  //   feeAmount: FeeAmount.LOWER,
  // },

  // {
  //   pid: 3,
  //   token0: mantleTokens.test1, // 0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE
  //   token1: mantleTokens.test2, // 0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8
  //   lpAddress: '0x16BCc2015c0214eD23f8949eAECF965CC91D07B8',
  //   feeAmount: FeeAmount.MEDIUM,
  // },
  // {
  //   pid: 2,
  //   token0: mantleTokens.usdc, // 0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE
  //   token1: mantleTokens.wmnt, // 0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8
  //   lpAddress: '0x48AeaE837b8ebac441Ed8d00B6C5df6D3208C673',
  //   feeAmount: FeeAmount.MEDIUM,
  // },
  // {
  //   pid: 3,
  //   token0: new ERC20Token(
  //     ChainId.MANTLE,
  //     '0x4206A257Dbc46e4145d31eE6b64717Bd455817a7',
  //     18,
  //     'TEST 1',
  //     'PancakeSwap Token',
  //     'https://mancakeswap.finance/',
  //   ),
  //   token1: mantleTokens.wmnt,
  //   lpAddress: '0xcb9C5e852d7874Ffab7F76DB78AF2cd05aFDd647',
  //   feeAmount: FeeAmount.MEDIUM,
  // },
])

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  // {
  //   pid: 0,
  //   v1pid: 0,
  //   lpSymbol: 'USDT-USDC LP',
  //   lpAddress: '0xf805D18e057450182df1b877319497344586Dbc8',
  //   token: mantleTokens.usdt,
  //   quoteToken: mantleTokens.usdc,
  //   stableSwapAddress: '0x1940Bf7e1d84A6f1f9F90A6290189601617736cb',
  //   infoStableSwapAddress: '0x17287d20cFd3bC884803816684333c853F2B06ef',
  //   stableLpFee: 0.0002,
  //   stableLpFeeRateOfTotalFee: 0.5,
  // },
  // {
  //   pid: 1,
  //   v1pid: 1,
  //   lpSymbol: 'A-JTEST LP',
  //   lpAddress: '0x1754F69669b74CDA879b5295e9Bb7FE3506ea27C',
  //   token: mantleTokens.stableA,
  //   quoteToken: mantleTokens.jtest,
  //   stableSwapAddress: '0x59dcbEd743Be740E0815E4A5fAB236F00e8Ad92E',
  //   infoStableSwapAddress: '0x0cF6c8b167e187C072f4F3e3E513fB2864648F53',
  //   stableLpFee: 0.0002,
  //   stableLpFeeRateOfTotalFee: 0.5,
  // },

  // live
  {
    pid: 3,
    v1pid: 3,
    lpSymbol: 'wETH-cmETH LP',
    lpAddress: '0xE8DA8AFa488b41cF03B1454f531AE431fb526597',
    token: mantleTokens.weth,
    quoteToken: mantleTokens.cmeth,
    stableSwapAddress: '0xbc86A916b7e3A5Dff827F632e607619026Ef96AC',
    infoStableSwapAddress: '0x0cF6c8b167e187C072f4F3e3E513fB2864648F53',
    stableLpFee: 0.00001, // 0.01%
    stableLpFeeRateOfTotalFee: 0.5, // 50%
  },
  {
    pid: 4,
    v1pid: 4,
    lpSymbol: 'mETH-cmETH LP',
    lpAddress: '0x58162Cc255d7c2D7a2748AeecEd12Af3F1860E9C',
    token: mantleTokens.meth,
    quoteToken: mantleTokens.cmeth,
    stableSwapAddress: '0xFAEfc4da8bc281c9fB077B7801A545992Aa1cba9',
    infoStableSwapAddress: '0x0cF6c8b167e187C072f4F3e3E513fB2864648F53',
    stableLpFee: 0.00001, // 0.01%
    stableLpFeeRateOfTotalFee: 0.5, // 50%
  },
  {
    pid: 5,
    v1pid: 5,
    lpSymbol: 'USDC-USDT LP',
    lpAddress: '0x1f2979DE82227Fe98b66B14542fA3a19Ab3902B2',
    token: mantleTokens.usdc,
    quoteToken: mantleTokens.usdt,
    stableSwapAddress: '0x5F512F833b44a3B019e814d21e79d12CD1A967d3',
    infoStableSwapAddress: '0x0cF6c8b167e187C072f4F3e3E513fB2864648F53',
    stableLpFee: 0.00001, // 0.01%
    stableLpFeeRateOfTotalFee: 0.5, // 50%
  },
  {
    pid: 6,
    v1pid: 6,
    lpSymbol: 'USDT-USDe LP',
    lpAddress: '0x904593e506C39347a0222D023D45f4b9EFCF366B',
    token: mantleTokens.usdt,
    quoteToken: mantleTokens.usde,
    stableSwapAddress: '0x4aD26D57D5B45cFF86473eed357ef9af78A4d4B4',
    infoStableSwapAddress: '0x0cF6c8b167e187C072f4F3e3E513fB2864648F53',
    stableLpFee: 0.00001, // 0.01%
    stableLpFeeRateOfTotalFee: 0.5, // 50%
  },
  {
    pid: 7,
    v1pid: 7,
    lpSymbol: 'mETH-wETH LP',
    lpAddress: '0xc7c4B3C80D367E4c2a4f51C494E9283587e2F576',
    token: mantleTokens.meth,
    quoteToken: mantleTokens.weth,
    stableSwapAddress: '0x60F31b557965884FAfa1c8e4c981d33fcd3Aef84',
    infoStableSwapAddress: '0x0cF6c8b167e187C072f4F3e3E513fB2864648F53',
    stableLpFee: 0.00001, // 0.01%
    stableLpFeeRateOfTotalFee: 0.5, // 50%
  },
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

// const farms: SerializedFarmConfig[] = []

export default farms
